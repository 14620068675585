exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-acoes-acao-de-doacao-de-alimentos-comunidade-porto-de-areia-tsx": () => import("./../../../src/pages/acoes/acao-de-doacao-de-alimentos-comunidade-porto-de-areia.tsx" /* webpackChunkName: "component---src-pages-acoes-acao-de-doacao-de-alimentos-comunidade-porto-de-areia-tsx" */),
  "component---src-pages-acoes-acao-de-doacao-de-roupas-comunidade-porto-de-areia-tsx": () => import("./../../../src/pages/acoes/acao-de-doacao-de-roupas-comunidade-porto-de-areia.tsx" /* webpackChunkName: "component---src-pages-acoes-acao-de-doacao-de-roupas-comunidade-porto-de-areia-tsx" */),
  "component---src-pages-acoes-acao-de-pascoa-na-comunidade-porto-de-areia-tsx": () => import("./../../../src/pages/acoes/acao-de-pascoa-na-comunidade-porto-de-areia.tsx" /* webpackChunkName: "component---src-pages-acoes-acao-de-pascoa-na-comunidade-porto-de-areia-tsx" */),
  "component---src-pages-acoes-primeira-acao-na-comunidade-do-murao-tsx": () => import("./../../../src/pages/acoes/primeira-acao-na-comunidade-do-murao.tsx" /* webpackChunkName: "component---src-pages-acoes-primeira-acao-na-comunidade-do-murao-tsx" */),
  "component---src-pages-doacoes-tsx": () => import("./../../../src/pages/doacoes.tsx" /* webpackChunkName: "component---src-pages-doacoes-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-projetos-aulas-de-bateria-tsx": () => import("./../../../src/pages/projetos/aulas-de-bateria.tsx" /* webpackChunkName: "component---src-pages-projetos-aulas-de-bateria-tsx" */),
  "component---src-pages-projetos-aulas-de-jiu-jitsu-tsx": () => import("./../../../src/pages/projetos/aulas-de-jiu-jitsu.tsx" /* webpackChunkName: "component---src-pages-projetos-aulas-de-jiu-jitsu-tsx" */),
  "component---src-pages-projetos-capacitacao-profissional-tsx": () => import("./../../../src/pages/projetos/capacitacao-profissional.tsx" /* webpackChunkName: "component---src-pages-projetos-capacitacao-profissional-tsx" */),
  "component---src-pages-projetos-instituto-de-vencedores-tsx": () => import("./../../../src/pages/projetos/instituto-de-vencedores.tsx" /* webpackChunkName: "component---src-pages-projetos-instituto-de-vencedores-tsx" */),
  "component---src-pages-projetos-revisao-de-vidas-tsx": () => import("./../../../src/pages/projetos/revisao-de-vidas.tsx" /* webpackChunkName: "component---src-pages-projetos-revisao-de-vidas-tsx" */),
  "component---src-pages-projetos-socioeducativo-tsx": () => import("./../../../src/pages/projetos/socioeducativo.tsx" /* webpackChunkName: "component---src-pages-projetos-socioeducativo-tsx" */),
  "component---src-pages-quem-somos-tsx": () => import("./../../../src/pages/quem-somos.tsx" /* webpackChunkName: "component---src-pages-quem-somos-tsx" */),
  "component---src-pages-voluntario-tsx": () => import("./../../../src/pages/voluntario.tsx" /* webpackChunkName: "component---src-pages-voluntario-tsx" */)
}

